import { HStack, useDisclosure } from '@chakra-ui/react'
import { useIsEmployeeView } from '../../hooks/id_token_claims'
import { useSelectedReportStatus, useSelectedReportType } from '../../pages/reports'
import {
  ReportStatus,
  STATUS_ACTION_LABELS,
  getStatusLabel,
} from '../../utils/constants'
import { getAllowedActionButtons } from '../report_detail/report_detail_button'
import BulkClassifyButton from './bulk_classify_button'
import BulkOpenButton from './bulk_open_button'
import BulkCopyButton from './bulk_copy_button'
import StatusChangeButton from './status_change_button'
import BulkScreenshotButton from './bulk_screenshot_button'
import BulkReroutePlatformButton from './bulk_reroute_platform_button'
import BulkTagButton from './bulk_tag_button'
import { DOPPEL_BREACH_RED } from '@/utils/style'
import { getDoppelReportUrl } from '@/utils/reports/report_utils'
import { useState } from 'react'
import DoppelModal from '../shared/doppel_modal'
import BulkReroutePlatformSubtypeButton from './bulk_reroute_platform_subtype_button'
import BulkNotesButton from './bulk_notes_button'

// State Machine (keep this up-to date!)
//
// Needs Review -> Reported, Ignored, No-Action, Internal Review, Internal Ignore
// Reported -> Needs Review, Delisted
// Delisted -> Needs Review, Reported
// Ignored -> Needs Review
// No Action -> Needs Review
// Internal Review -> Needs Review, Internal Ignore
// Internal Ignore -> Needs Review, Internal Review

// Currently only used in Spoof Table, need to refactor to make it more generic
const BulkActionButtons = ({
  selectedRows,
  actionLoadingState,
  updateReportFunc,
  isOrgUnifiedView,
}) => {
  const [selectedReportStatus] = useSelectedReportStatus()
  const [selectedReportType] = useSelectedReportType()
  const [isEmployeeView] = useIsEmployeeView()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [pendingAction, setPendingAction] = useState(null)

  const handleActionClick = (action, callback) => {
    setPendingAction({ action, callback, isDelete: action === 'Internal Archived' })
    onOpen()
  }

  const handleConfirm = () => {
    if (pendingAction) {
      pendingAction.callback()
    }
    onClose()
  }

  if (selectedRows.length === 0) {
    return <></>
  }

  return (
    <>
      <HStack spacing={2}>
        {getAllowedActionButtons(selectedReportStatus, isEmployeeView).map(
          (reportStatus: ReportStatus) => (
            <StatusChangeButton
              actionLoadingState={actionLoadingState}
              handleClick={() =>
                handleActionClick(
                  getStatusLabel(reportStatus, selectedReportType),
                  () => updateReportFunc(selectedRows, { status: reportStatus }),
                )
              }
              key={reportStatus}
              selectedRows={selectedRows}
              statusTo={reportStatus}
              text={getStatusLabel(
                reportStatus,
                selectedReportType,
                STATUS_ACTION_LABELS,
              )}
            />
          ),
        )}

        <BulkTagButton
          isOrgUnifiedView={isOrgUnifiedView}
          selectedRows={selectedRows}
        />

        <BulkNotesButton
          selectedRows={selectedRows}
          updateReportFunc={updateReportFunc}
        />
      </HStack>

      <HStack mt={2} spacing={2}>
        <BulkScreenshotButton selectedRows={selectedRows} />

        {isEmployeeView && (
          <BulkOpenButton
            selectedRows={selectedRows}
            selectorFunc={(row) =>
              getDoppelReportUrl(row.original, row.original.organization)
            }
          />
        )}

        {isEmployeeView && <BulkCopyButton selectedRows={selectedRows} />}

        {isEmployeeView && (
          <BulkClassifyButton
            loading={actionLoadingState?.classification !== undefined}
            selectedRows={selectedRows}
            updateReportFunc={updateReportFunc}
          />
        )}

        {isEmployeeView && <BulkReroutePlatformButton selectedRows={selectedRows} />}

        {isEmployeeView && (
          <BulkReroutePlatformSubtypeButton
            loading={actionLoadingState?.platformSubtype !== undefined}
            selectedRows={selectedRows}
            updateAlertFunc={updateReportFunc}
          />
        )}

        {isEmployeeView && (
          <StatusChangeButton
            actionLoadingState={actionLoadingState}
            bgColor={DOPPEL_BREACH_RED}
            handleClick={() =>
              handleActionClick(
                getStatusLabel(ReportStatus.ENUM_INTERNAL_ARCHIVED, selectedReportType),
                () =>
                  updateReportFunc(selectedRows, {
                    status: ReportStatus.ENUM_INTERNAL_ARCHIVED,
                  }),
              )
            }
            key={ReportStatus.ENUM_INTERNAL_ARCHIVED}
            selectedRows={selectedRows}
            statusTo={ReportStatus.ENUM_INTERNAL_ARCHIVED}
            text={'Delete'}
          />
        )}
      </HStack>

      <DoppelModal
        isDanger={pendingAction?.isDelete}
        isOpen={isOpen}
        message={
          pendingAction?.isDelete
            ? `Are you sure you want to delete ${selectedRows.length} reports?`
            : `Are you sure you want to move ${selectedRows.length} reports to ${pendingAction?.action}?`
        }
        onClose={onClose}
        primaryAction={handleConfirm}
        primaryLabel="Confirm"
        title="Confirm Action"
      />
    </>
  )
}

export default BulkActionButtons
