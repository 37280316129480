import { Box, Flex, Text, VStack, Image, Checkbox } from '@chakra-ui/react'
import React from 'react'

import {
  ReportStatus,
  ReportType,
  Classification,
  SCORE_ENABLED_REPORT_TYPES,
  CLASSIFICATION_TO_LABEL,
  DarkWebPlatforms,
  SEVERITY_TO_LABEL,
} from '../../utils/constants'
import TablePreview, {
  TablePreviewSize,
  previewSizeToPixels,
} from '../tables/table_preview'
import { getFlaggedPreviewImage } from '../../utils/web2'
import DoppelLink from '../report_detail/doppel_link'
import TableTimestamp from '../tables/table_timestamp'
import ReportEnforcementStatus from './report_enforcement_status'
import {
  getColorForSeverity,
  getColorForSpoofStatus,
} from './spoof_status_tag_with_menu'
import RichText from '../shared/rich_text'
import {
  getContextAroundHighlightedContent,
  getStringAfterFirstHyphen,
} from '../../utils/string_utils'
import {
  getExternalReportId,
  getReportSourceDisplay,
  REPORT_TYPES_WITH_PLATFORMS,
} from '../../utils/reports/report_utils'
import { shouldDefaultToHttp } from '../../utils/feature_flags'
import CountryCode from '../shared/country_code'
import { DoppelTag } from '../shared/doppel_tag'

import parsePhoneNumberFromString from 'libphonenumber-js'
import { sortTagsAlphabetically } from '../../utils/sort'
import TagGroup from '../shared/tag_group'
import { DOPPEL_SCRAPING_IN_PROGRESS } from '@/utils/image'
import { getSpoofMatchScreenshotUrl } from './spoof_match'
import { getPlatformSubtypeDisplayValue } from '@/utils/reports/platform_subtype_utils'
import SpoofReportsTableLastReportedDateCell from './spoof_reports_table_last_reported_date_cell'

const MATCH_IMAGES_SHOWN = 4
const TRANSPARENT_IMAGE =
  'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='
const IMAGE_GAP_SIZE = 1

export const getCommonReportColumns = (
  reportType: ReportType,
  reportStatus: ReportStatus,
  loading: boolean,
  isEmployeeView: boolean,
) => {
  return [
    {
      id: 'state',
      Header: 'State',
      accessor: 'spoof_status',
      disableSortBy: true,
      show:
        reportType !== ReportType.DARK_WEB &&
        reportStatus !== ReportStatus.ENUM_RESOLVED,
      Cell: (cellInfo) => (
        <DoppelTag
          bgColor={getColorForSpoofStatus(cellInfo.value)}
          color={'#FFFFFF'}
          loading={loading}
          tag={CLASSIFICATION_TO_LABEL[cellInfo.value]}
        />
      ),
    },
    {
      id: 'severity',
      Header: 'Severity',
      accessor: 'severity',
      disableSortBy: true,
      show:
        reportType !== ReportType.DARK_WEB &&
        reportStatus !== ReportStatus.ENUM_RESOLVED,
      Cell: ({ value, row }) =>
        row.original.spoof_status === Classification.ACTIVE && value ? (
          <DoppelTag
            bgColor={getColorForSeverity(value)}
            color={'#FFFFFF'}
            loading={loading}
            tag={SEVERITY_TO_LABEL[value]}
          />
        ) : null,
    },
    {
      id: 'suspicion_score',
      Header: 'Score',
      accessor: 'score',
      disableSortBy: true,
      // TODO: remove SCORE_ENABLED_REPORT_TYPES filter once we add score to all other report types
      show: isEmployeeView && SCORE_ENABLED_REPORT_TYPES.has(reportType),
      Cell: (cellInfo) => <Text noOfLines={1}>{cellInfo.value?.toFixed(2)}</Text>,
    },
    {
      Header: 'Brand',
      accessor: (a) => a.spoofing_entity?.entity_name || '',
      disableSortBy: true,
      Cell: (cellInfo) => (
        <Text noOfLines={1} width="100px">
          {cellInfo.value}
        </Text>
      ),
    },
    notesColumn,
    generateTagsColumn(isEmployeeView),
  ]
}
export const getSpoofingReportColumns = (
  reportType: ReportType,
  reportStatus: ReportStatus,
  isOrgUnifiedView: boolean,
  orgId: string,
  showPreviews: boolean,
  isEmployeeView: boolean,
  orgData: any,
  loading: boolean,
) => {
  if (reportType == ReportType.DARK_WEB) return []
  const reportSpecificColumns = [
    dateCreatedColumn,
    {
      id: 'organization',
      Header: 'Org',
      accessor: 'organization',
      disableSortBy: true,
      show: isOrgUnifiedView,
      Cell: (cellInfo) => (
        <Text fontSize={13} noOfLines={2} width="90px">
          {cellInfo.value.name}
        </Text>
      ),
    },
    idColumn,
    {
      id: 'url',
      Header: reportType == ReportType.DOMAINS ? 'Domain' : 'URL',
      accessor: (a) => {
        return a
      },
      disableSortBy: true,
      show: reportType !== ReportType.TELCO,
      Cell: (cellInfo) => {
        const url = cellInfo.value.flagged_url
        return (
          <Box width={175}>
            <DoppelLink
              disableLink={true}
              href={cellInfo.value.unsanitized_url || url}
              isDefanged={true}
              name={url}
              noOfLines={3}
              shouldDefaultToHttp={shouldDefaultToHttp(orgId)}
            />
          </Box>
        )
      },
    },
    {
      id: 'phone_number',
      Header: 'Phone Number',
      accessor: (a) => {
        return a.spoof_matches[0]?.phone_number
      },
      disableSortBy: true,
      show: reportType === ReportType.TELCO,
      Cell: (cellInfo) => (
        <Text noOfLines={1} width="150px">
          {cellInfo.value?.number}
        </Text>
      ),
    },
    {
      id: 'provider',
      Header: 'Provider',
      accessor: (a) => {
        return a.spoof_matches[0]?.phone_number
      },
      disableSortBy: true,
      show: reportType === ReportType.TELCO,
      Cell: (cellInfo) => (
        <Text noOfLines={1} width="150px">
          {cellInfo.value?.telco_provider}
        </Text>
      ),
    },
    {
      id: 'last_reported_at',
      Header: 'Date Reported',
      accessor: 'last_reported_at',
      disableSortBy: true,
      show: reportStatus === ReportStatus.ENUM_REPORTED,
      Cell: (cellInfo) => (
        <SpoofReportsTableLastReportedDateCell
          isEmployeeView={isEmployeeView}
          lastReportedAt={cellInfo.value}
        />
      ),
    },
    {
      id: 'last_resolved_at',
      Header: 'Date Taken Down',
      accessor: 'last_resolved_at',
      disableSortBy: true,
      show: reportStatus === ReportStatus.ENUM_RESOLVED,
      Cell: (cellInfo) => <TableTimestamp date={cellInfo.value} />,
    },
    // {
    //   id: 'approvals',
    //   Header: 'Approvals',
    //   accessor: 'approvals',
    //   show: reportStatus === ReportStatus.ENUM_NEEDS_REVIEW && isEmployeeView,
    //   Cell: (cellInfo) => <ApprovalStatuses approvals={cellInfo.value} />,
    // },
    generatePreviewColumn(showPreviews, isEmployeeView),
    {
      id: 'type',
      Header: 'Type',
      accessor: 'platform_subtype',
      show: REPORT_TYPES_WITH_PLATFORMS.includes(reportType),
      disableSortBy: true,
      Cell: (cellInfo) => (
        <Text noOfLines={1} width="70px">
          {getPlatformSubtypeDisplayValue(cellInfo.value?.name, reportType)}
        </Text>
      ),
    },
    {
      id: 'enforcements',
      Header: 'Mitigation',
      accessor: 'enforcement_requests',
      disableSortBy: true,
      show: reportStatus === ReportStatus.ENUM_REPORTED,
      Cell: (cellInfo) => (
        <Box width="100px">
          <ReportEnforcementStatus enforcement_requests={cellInfo.value} />
        </Box>
      ),
    },
    {
      id: 'followers',
      Header: 'Followers',
      accessor: (a) => {
        return a.spoof_matches[0]?.social_media_data?.num_followers
      },
      disableSortBy: true,
      show: reportType === ReportType.SOCIAL_MEDIA,
      Cell: (cellInfo) => (
        <Text noOfLines={1} width="100px">
          {cellInfo.value}
        </Text>
      ),
    },
    {
      id: 'country',
      Header: 'CN.',
      accessor: (a) => a.first_domain_data[0]?.full_url?.domain_data || a.domain_data,
      disableSortBy: true,
      show: reportType === ReportType.DOMAINS,
      Cell: (cellInfo) =>
        cellInfo.value?.country_code && (
          <Box width="35px">
            <CountryCode
              countryCode={cellInfo.value.country_code}
              tooltip={cellInfo.value.country_code}
            />
          </Box>
        ),
    },
    {
      id: 'nameserver',
      Header: 'Nameservers',
      accessor: (a) => a.first_domain_data[0]?.full_url?.domain_data || a.domain_data,
      disableSortBy: true,
      show: reportType === ReportType.DOMAINS,
      Cell: (cellInfo) => {
        if (!cellInfo.value?.raw_data) {
          return <></>
        }
        try {
          const parsedData = JSON.parse(cellInfo.value?.raw_data)
          if (
            !parsedData?.whois?.name_servers ||
            !Array.isArray(parsedData?.whois?.name_servers)
          ) {
            return <></>
          }

          return parsedData.whois.name_servers.map((nameServer, index) => (
            <Text fontSize="11" key={index} noOfLines={1} width="150px">
              {nameServer}
            </Text>
          ))
        } catch (e) {
          return <></>
        }
      },
    },
    {
      id: 'MX',
      Header: 'MX',
      accessor: (a) => a.first_domain_data[0]?.full_url?.domain_data || a.domain_data,
      disableSortBy: true,
      show: reportType === ReportType.DOMAINS,
      Cell: (cellInfo) => {
        const rawData = cellInfo.value?.raw_data

        if (!rawData) {
          return <></>
        }

        const parsedData = JSON.parse(rawData)
        const mxRecords = parsedData?.dns?.MX

        if (!Array.isArray(mxRecords) || mxRecords.length === 0) {
          // If there are no MX records, render an unchecked checkbox
          return <></>
        }

        return <Checkbox isChecked={true} />
      },
    },
    {
      id: 'telco_country',
      Header: 'Country',
      accessor: (a) => a.spoof_matches[0]?.phone_number,
      disableSortBy: true,
      show: reportType === ReportType.TELCO,
      Cell: (cellInfo) => {
        const { country_code, number: full_number } = cellInfo.value || {}
        if (!country_code || !full_number) return null

        let flagCode = country_code.toLowerCase()
        const phoneNumber = parsePhoneNumberFromString(full_number)
        if (phoneNumber) {
          const country = phoneNumber.country
          flagCode = country?.toLowerCase()
        }

        if (!flagCode) return null

        const toolTip = `${flagCode.toUpperCase()} (${country_code})`

        return <CountryCode countryCode={flagCode} tooltip={toolTip} />
      },
    },
    {
      id: 'source',
      Header: 'Source',
      accessor: (a) => a,
      disableSortBy: true,
      Cell: (cellInfo) => {
        if (cellInfo?.value == null) {
          return <></>
        }
        return (
          <Text noOfLines={3} width="150px">
            {getReportSourceDisplay(cellInfo.value, isEmployeeView, orgData?.name)}
          </Text>
        )
      },
    },
    {
      id: 'estimated_usd_price',
      Header: 'Estimated Value',
      accessor: 'estimated_usd_price',
      disableSortBy: true,
      show: reportType === ReportType.CRYPTO,
      Cell: (cellInfo) => {
        if (!cellInfo?.value) {
          return <>-</>
        }
        return (
          <Text>
            ${cellInfo?.value.toLocaleString(undefined, { maximumFractionDigits: 2 })}
          </Text>
        )
      },
    },
  ]
  return [
    ...reportSpecificColumns,
    ...getCommonReportColumns(reportType, reportStatus, loading, isEmployeeView),
  ]
}

export const getDarkWebColumns = (
  reportStatus: ReportStatus,
  reportPlatform: DarkWebPlatforms,
  loading: boolean,
  isEmployeeView: boolean,
) => {
  const darkWebColumns = []

  darkWebColumns.push(
    ...[
      dateCreatedColumn,
      {
        Header: 'Crawled At',
        accessor: (a) => a.spoof_matches[0]?.dark_web?.crawled_at || '',
        disableSortBy: true,
        Cell: (cellInfo) => <TableTimestamp date={cellInfo.value} />,
      },
    ],
  )

  if (reportPlatform === DarkWebPlatforms.CRED_LEAKS) {
    darkWebColumns.push(
      ...[
        {
          Header: 'User',
          accessor: (a) => a.spoof_matches[0]?.dark_web?.title || '',
          disableSortBy: true,
          Cell: (cellInfo) => (
            <Text noOfLines={3} width="300px">
              {cellInfo.value.split(':')[0]}
            </Text>
          ),
        },
        {
          Header: 'Password',
          accessor: (a) => a.spoof_matches[0]?.dark_web?.title || '',
          disableSortBy: true,
          Cell: (cellInfo) => (
            <Text noOfLines={3} width="200px">
              {cellInfo.value.split(':')[1]}
            </Text>
          ),
        },
        {
          Header: 'Credential Url',
          accessor: (a) => a.spoof_matches[0]?.dark_web?.credential_url || '',
          disableSortBy: true,
          Cell: (cellInfo) => (
            <Text noOfLines={3} width="400px">
              {cellInfo.value}
            </Text>
          ),
        },
      ],
    )
  }

  if (reportPlatform === DarkWebPlatforms.GENERIC) {
    darkWebColumns.push(
      ...[
        {
          Header: 'Highlight',
          accessor: (a) => a.spoof_matches[0]?.dark_web?.body || '',
          disableSortBy: true,
          Cell: (cellInfo) => (
            <Text noOfLines={3} width="160px">
              <RichText html={getContextAroundHighlightedContent(cellInfo.value)} />
            </Text>
          ),
        },
        {
          Header: 'Title',
          accessor: (a) => a.spoof_matches[0]?.dark_web?.title || '',
          disableSortBy: true,
          Cell: (cellInfo) => (
            <Text noOfLines={3} width="400px">
              {cellInfo.value}
            </Text>
          ),
        },
      ],
    )
  }

  if (reportPlatform === DarkWebPlatforms.CREDIT_CARD_LEAKS) {
    darkWebColumns.push(
      ...[
        {
          Header: 'BIN',
          accessor: (a) => a.spoof_matches[0]?.dark_web?.credit_card?.bin || '',
          disableSortBy: true,
          Cell: (cellInfo) => (
            <Text noOfLines={3} width="70px">
              {cellInfo.value}
            </Text>
          ),
        },
        {
          Header: 'CCN',
          accessor: (a) => a.spoof_matches[0]?.dark_web?.credit_card || '',
          disableSortBy: true,
          Cell: (cellInfo) => {
            const bin = (cellInfo.value?.bin || '').toString() // Convert bin to string
            const ccn = cellInfo.value?.credit_card_number || ''
            // Remove the BIN from the credit card number
            const trimmedCcn = ccn.slice(bin.length)
            // Mask all but the last 4 digits
            const maskedCcn =
              trimmedCcn.slice(0, -4).replace(/\d/g, '*') + trimmedCcn.slice(-4)

            return (
              <Text noOfLines={3} width="80px">
                {maskedCcn}
              </Text>
            )
          },
        },
        {
          Header: 'Expiration Date',
          accessor: (a) => a.spoof_matches[0]?.dark_web?.credit_card || '',
          disableSortBy: true,
          Cell: (cellInfo) => {
            const expirationDate = cellInfo.value?.expiration_date || ''
            // Extract year and month from the expiration date
            const formattedDate = expirationDate ? expirationDate.slice(0, 7) : ''

            return (
              <Text noOfLines={3} width="100px">
                {formattedDate}
              </Text>
            )
          },
        },
        {
          Header: 'Cardholder Name',
          accessor: (a) => a.spoof_matches[0]?.dark_web?.credit_card || '',
          disableSortBy: true,
          Cell: (cellInfo) => {
            const cardholder_name = cellInfo.value?.cardholder_name || ''
            return (
              <Text noOfLines={3} width="100px">
                {cardholder_name}
              </Text>
            )
          },
        },
        {
          Header: 'Cardholder Address',
          accessor: (a) => a.spoof_matches[0]?.dark_web?.credit_card || '',
          disableSortBy: true,
          Cell: (cellInfo) => {
            const cardholder_address = cellInfo.value?.cardholder_address || ''
            return (
              <Text noOfLines={3} width="100px">
                {cardholder_address}
              </Text>
            )
          },
        },
      ],
    )
  }

  darkWebColumns.push(
    ...[
      {
        id: 'Network',
        Header: 'Network',
        accessor: (a) => a.spoof_matches[0]?.dark_web?.network || '',
        disableSortBy: true,
        Cell: (cellInfo) => (
          <Text noOfLines={1} width="100px">
            {cellInfo.value}
          </Text>
        ),
      },
    ],
  )

  if (reportPlatform !== DarkWebPlatforms.CREDIT_CARD_LEAKS) {
    darkWebColumns.push(
      ...[
        {
          id: 'source',
          Header: 'Source',
          accessor: (a) => a.spoof_matches[0]?.dark_web?.source || '',
          disableSortBy: true,
          Cell: (cellInfo) => (
            <Text noOfLines={1} width="100px">
              {getStringAfterFirstHyphen(cellInfo.value)}
            </Text>
          ),
        },
      ],
    )
  }

  return [
    ...darkWebColumns,
    ...getCommonReportColumns(
      ReportType.DARK_WEB,
      reportStatus,
      loading,
      isEmployeeView,
    ),
  ]
}

export const getSuspiciousEmailColumns = (isEmployeeView: boolean) => {
  return [
    dateCreatedColumn,
    idColumn,
    {
      Header: 'Subject',
      accessor: (a) => a.spoof_matches[0]?.email?.subject,
      disableSortBy: true,
      Cell: (cellInfo) => (
        <Text fontSize="10" noOfLines={2} width="250px">
          {cellInfo.value}
        </Text>
      ),
    },
    notesColumn,
    generateTagsColumn(isEmployeeView),
    // add other columns later
  ]
}

const getTagsColumn = (reportTags, isEmployeeView: boolean) => {
  const sortedTags = reportTags ? [...reportTags].sort(sortTagsAlphabetically) : []
  return (
    sortedTags.length > 0 &&
    (isEmployeeView ||
      sortedTags.some((reportTag) => !reportTag?.tag?.is_internal)) && (
      <TagGroup
        data={sortedTags
          .filter((reportTag) => isEmployeeView || !reportTag?.tag?.is_internal)
          .map((reportTag) => reportTag?.tag?.name)}
      />
    )
  )
}

const renderReportPreview = ({ cellInfo }) => {
  const classifications = Object.values(Classification)
  const sortedSpoofMatches = [...cellInfo.value.spoof_matches].sort(
    (a, b) =>
      classifications.indexOf(a.classification) -
      classifications.indexOf(b.classification),
  )

  const offset = sortedSpoofMatches?.length === 1 ? 1 : 0
  const screenshotUrls = (sortedSpoofMatches || [])
    .slice(offset, MATCH_IMAGES_SHOWN + offset) // Limit to the first 4 matches before mapping to reduce unnecessary iterations
    // TODO: Add screenshot blocked logic here
    .map((match) => getSpoofMatchScreenshotUrl(match) || DOPPEL_SCRAPING_IN_PROGRESS)

  // In case no match is found, use a transparent image to maintain symmetric dimensions on layout
  if (screenshotUrls.length === 0) {
    screenshotUrls.push(TRANSPARENT_IMAGE)
  }

  const flaggedPreviewImage = getFlaggedPreviewImage(cellInfo.value, sortedSpoofMatches)

  return (
    <Flex align="start">
      <TablePreview previewSize={TablePreviewSize.LARGE} src={flaggedPreviewImage} />

      <VStack marginLeft="2" spacing={IMAGE_GAP_SIZE}>
        {screenshotUrls.map((imageUrl, index) => {
          // eslint-disable-next-line jsx-a11y/alt-text
          return (
            <Image
              boxSize={
                previewSizeToPixels[TablePreviewSize.LARGE] / MATCH_IMAGES_SHOWN -
                (MATCH_IMAGES_SHOWN - 1 * IMAGE_GAP_SIZE)
              }
              key={index}
              src={imageUrl}
            />
          )
        })}
      </VStack>
    </Flex>
  )
}

const dateCreatedColumn = {
  Header: 'Date Created',
  accessor: 'created_at',
  disableSortBy: true,
  Cell: (cellInfo) => <TableTimestamp date={cellInfo.value} />,
}

const idColumn = {
  Header: 'Id',
  accessor: (a) => {
    return a
  },
  disableSortBy: true,
  Cell: (cellInfo) => (
    <Text fontSize={12} width="80px">
      {getExternalReportId(
        cellInfo.value.organization?.abbr_name,
        cellInfo.value.external_id,
      )}
    </Text>
  ),
}

const generatePreviewColumn = (showPreviews: boolean, isEmployeeView: boolean) => {
  return {
    id: 'preview',
    Header: 'Preview',
    accessor: (a) => {
      return a
    },
    disableSortBy: true,
    show: showPreviews || isEmployeeView,
    Cell: (cellInfo) =>
      renderReportPreview({
        cellInfo,
      }),
  }
}

const notesColumn = {
  Header: 'Notes',
  accessor: 'notes',
  disableSortBy: true,
  Cell: (cellInfo) => (
    <Text fontSize="10" noOfLines={2} width="250px">
      {cellInfo.value}
    </Text>
  ),
}

const generateTagsColumn = (isEmployeeView: boolean) => {
  return {
    Header: 'Tags',
    accessor: 'report_tags',
    disableSortBy: true,
    Cell: (cellInfo) => getTagsColumn(cellInfo.value, isEmployeeView),
  }
}
